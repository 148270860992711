var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { transition: "dialog-top-transition", "max-width": "600px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b({ attrs: { text: "" } }, "v-btn", attrs, false),
                  on
                ),
                [_vm._v(" Login ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "justify-space-between" },
            [
              _c("span", { staticClass: "text-h5" }, [_vm._v("Login")]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Fermer ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.login.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  label: "Email*",
                                  rules: [
                                    (v) => !!v || "Ce champ est requis",
                                    (v) =>
                                      /.+@.+/.test(v) ||
                                      "L'email doit être valide",
                                  ],
                                },
                                model: {
                                  value: _vm.form.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  label: "Password*",
                                  rules: [(v) => !!v || "Ce champ est requis"],
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-checkbox", {
                                attrs: { label: "Remember me" },
                                model: {
                                  value: _vm.form.remember,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remember", $$v)
                                  },
                                  expression: "form.remember",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center",
                              attrs: { cols: "12", sm: "6" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    type: "submit",
                                    color: "brown",
                                    disabled: !_vm.valid,
                                  },
                                },
                                [_vm._v("Envoyer")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("small", [_vm._v("*indicates required field")]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }