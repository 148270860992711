export default {
    namespaced: true,
    state: {
        composers: [],
        years: [],
        year: false,
        composer: {},
        prev: false,
        next: false,
        filters: [],
        transition: 0
    },
    mutations: {
        setComposers(state, data) {
            if (data) {
                state.years = data.years
                state.year = data.year
                state.composers = data.composers;
                state.transition++
            } else {
                state.years = []
                state.year = ''
                state.composers = [];
            }
        },
        setComposer(state, data) {
            if (data) {
                state.prev = data.prev
                state.next = data.next
                state.composer = data.composer;
            } else {
                state.prev = false
                state.next = false
                state.composer = false;
            }

        }
    },
    actions: {
        loadComposersAll({ commit, state, rootGetters }) {
            return axios.get(`/api/composers`, rootGetters.params).then(res => {
                state.composers = res.data;
            });
        },
        loadComposerByYear({ commit, rootGetters }, year) {
            let url = '/api/composers/year' + (year ? '/' + year : '')       
            return axios.get(url, rootGetters.params).then(res => {
                commit('setComposers', res.data)
            });
        },
        loadComposer({ commit, rootGetters }, composerId) {
            return axios.get(`/api/composers/${composerId}`, rootGetters.params).then((res) => {
                commit('setComposer', res.data)
            })
        },
        loadComposerSearch({ commit, rootGetters }, search) {
            if (search.length < 2) return []
            let params = _.cloneDeep(rootGetters.params)
            if (search)
                params.params.search = search
            return axios.get(`/api/composers/search`, params).then(res => {
                return res.data
            });
        },
        createComposer({ rootGetters, state }, composer) {
            return axios.post(`/api/composers`, composer, rootGetters.params).then(res => {
                state.composer = res.data.composer
                return state.composer
            });
        },
        composersUpdate({ rootGetters, state }, composer) {
            return axios.put(`/api/composers/${composer.id}`, composer, rootGetters.params).then(res => {
                state.composer = res.data.composer
            });
        },
        composersCheck({ rootGetters, state }, composer) {
            return axios.post(`/api/composers/${composer.id}/check`, composer, rootGetters.params).then(res => {
                state.composer = res.data.composer
            });
        },
        notesNew({ rootGetters, state }, composer) {
            return axios.post(`/api/composers/notes/${composer.id}`, { note: composer.note }, rootGetters.params).then(res => {
                state.composer = res.data.composer
            });
        },
        notesDelete({ rootGetters, state }, composer) {
            return axios.delete(`/api/composers/notes/${composer.id}/${composer.linkId}`, rootGetters.params).then(res => {
                state.composer = res.data.composer
            });
        },
        linksNew({ commit, state, rootState }, composer) {
            return axios.post(`/api/composers/${composer.id}/link`, { link: composer.link }).then(res => {
                state.composer = res.data.composer
            });
        },
        linksDelete({ commit, state, rootState }, composer) {
            return axios.delete(`/api/composers/${composer.id}/link/${composer.linkId}`).then(res => {
                state.composer = res.data.composer
            });
        }
    }
};
