<template>
  <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on"> Login </v-btn>
    </template>
    <v-card>
      <v-card-title class="justify-space-between">
        <span class="text-h5">Login</span> <v-btn text @click="dialog = false"> Fermer </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" @submit.prevent="login" ref="form">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field type="text" v-model="form.email" label="Email*" :rules="[(v) => !!v || 'Ce champ est requis', (v) => /.+@.+/.test(v) || 'L\'email doit être valide']"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field type="text" v-model="form.password" label="Password*" :rules="[(v) => !!v || 'Ce champ est requis']"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6"> <v-checkbox v-model="form.remember" label="Remember me"></v-checkbox> </v-col>
              <v-col cols="12" sm="6" class="d-flex align-center">
                <v-btn type="submit" color="brown" :disabled="!valid">Envoyer</v-btn>
              </v-col>
              <v-col cols="12"><small>*indicates required field</small></v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>    
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      valid: false,
      form: {},
    };
  },
  methods: {
    login() {
      if (this.$refs.form.validate())
        axios.post(`/login`, this.form).then((res) => {
          location.reload();
        });
    },
  },
};
</script>

<style scoped lang="scss">
.v-dialog__content {
  align-items: start;
}
</style>
