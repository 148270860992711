export default {
    namespaced: true,
    state: {
        pieces: [],
        years: [],
        year: false,
        piece: {},
        previousPiece: false,
        nextPiece: false,
        filters: [],
        transition: 0
    },
    mutations: {
        setPieces(state, data) {
            if (data) {
                state.years = data.years
                state.year = data.year
                state.pieces = data.pieces;
                state.transition++
            } else {
                state.years = []
                state.year = false
                state.pieces = [];
            }
        },
        setPiece(state, data) {
            if (data) {
                state.prev = data.prev
                state.next = data.next
                state.piece = data.piece;
            } else {
                state.prev = false
                state.next = false
                state.piece = false;
            }
        }
    },
    actions: {
        loadPiecesAll({ commit, state, rootGetters }) {
            state.pieces = []
            return axios.get(`/api/pieces`, rootGetters.params).then(res => {
                state.pieces = res.data;
            });
        },
        loadPiecesByYear({ commit, state, rootGetters }, year = '') {
            let url = '/api/pieces/year' + (year ? '/' + year : '')
            return axios.get(url, rootGetters.params).then(res => {
                commit('setPieces', res.data)
            });
        },
        loadPiece({ commit, state, rootGetters }, data) {
            let params = _.cloneDeep(rootGetters.params)
            if (data.withComposer)
                params.params.withComposer = data.withComposer

            return axios.get(`/api/pieces/${data.pieceId}`, params).then((res) => {
                state.piece = res.data.piece
                state.previousPiece = res.data.prev
                state.nextPiece = res.data.next
            })
        },
        loadPieceSearch({ commit, rootGetters }, search) {
            if (search.length < 2) return []
            let params = _.cloneDeep(rootGetters.params)
            if (search)
                params.params.search = search
            return axios.get(`/api/pieces/search`, params).then(res => {
                return res.data
            });
        },
        createPiece({ commit, state, rootState }, piece) {
            return axios.post(`/api/pieces`, piece).then(res => {
                state.piece = res.data.piece
                return state.piece
            });
        },
        piecesUpdate({ commit, state, rootState }, piece) {
            return axios.put(`/api/pieces/${piece.id}`, piece).then(res => {
                state.piece = res.data.piece
            });
        },
        piecesCheck({ commit, state, rootState }, piece) {
            return axios.post(`/api/pieces/${piece.id}/check`, piece).then(res => {
                state.piece = res.data.piece
            });
        },
        notesNew({ commit, state, rootState }, piece) {
            return axios.post(`/api/pieces/notes/${piece.id}`, { note: piece.note }).then(res => {
                state.piece = res.data.piece
            });
        },
        notesDelete({ commit, state, rootState }, piece) {
            return axios.delete(`/api/pieces/notes/${piece.id}/${piece.linkId}`).then(res => {
                state.piece = res.data.piece
            });
        },
        instrumentsListNew({ commit, state, rootState }, piece) {
            return axios.post(`/api/pieces/${piece.id}/instrument/${piece.instrument}`).then(res => {
                state.piece = res.data.piece
            });
        },
        instrumentsListDelete({ commit, state, rootState }, piece) {
            return axios.delete(`/api/pieces/${piece.id}/instrument/${piece.instrument}`).then(res => {
                state.piece = res.data.piece
            });
        },
        linksNew({ commit, state, rootState }, piece) {
            return axios.post(`/api/pieces/${piece.id}/link`, { link: piece.link }).then(res => {
                state.piece = res.data.piece
            });
        },
        linksDelete({ commit, state, rootState }, piece) {
            return axios.delete(`/api/pieces/${piece.id}/link/${piece.linkId}`).then(res => {
                state.piece = res.data.piece
            });
        }
    }
}
