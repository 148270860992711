import Vue from 'vue'
import Vuex from 'vuex'

import pieces from './pieces'
import composers from './composers'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user: false,
        countries: [],
        filters: { country: [], type: [], instrument: [] },
        types: [],
        instruments: [],
        uniqDate: []
    },
    getters: {
        params: (state) => {
            return {
                params: {
                    countryId: state.filters.country,
                    typeId: state.filters.type,
                    instrumentId: state.filters.instrument,
                    pieces: state.pieces.filters,
                    composers: state.composers.filters
                }
            };
        },
    },
    modules: {
        pieces,
        composers
    },
    actions: {
        async init(store) {
            await axios
                .get(
                    `/api/store`
                )
                .then(res => {                 
                    store.state.user = res.data.user
                    store.state.types = res.data.types
                    store.state.countries = res.data.countries
                    store.state.instruments = res.data.instruments
                    store.state.info = res.data.info
                })
        }
    }
})

export default store
