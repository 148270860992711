import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

Vue.use(VueRouter)

const router = new VueRouter({

    mode: 'history',
    routes: [{
        path: '/',
        component: () => import('../components/home/Index.vue'),
        name: 'home',
    },
    {
        path: '/composers',
        component: () => import('../components/composers/Index.vue'),
        name: 'composers'
    },
    {
        path: '/composers/:composerId',
        component: () => import('../components/composer/Index.vue'),
        name: 'composer'
    },
    {
        path: '/pieces',
        component: () => import('../components/pieces/Index.vue'),
        name: 'pieces'
    }, {
        path: '/pieces/:pieceId',
        component: () => import('../components/piece/Index.vue'),
        name: 'piece'
    },
    {
        path: '/contact',
        component: () => import('../components/contact/Index.vue'),
        name: 'contact'
    },   
    {
        path: '/admin',
        component: () => import('../components/menu/MenuAdmin.vue'),
        name: 'admin',
        beforeEnter: (to, from, next) => {
            if (store.state.user)
                next()
            else window.location.replace(window.location.origin + "/login")
        },
        children: [{
            path: 'composers',
            component: () => import('../components/admin/ComposersList.vue'),
            name: 'admin.composers'
        },
        {
            path: 'pieces',
            component: () => import('../components/admin/PiecesList.vue'),
            name: 'admin.pieces'
        },
        {
            path: 'instruments',
            component: () => import('../components/admin/InstrumentsList.vue'),
            name: 'admin.instruments'
        },
        {
            path: 'composers/new',
            component: () => import('../components/admin/ComposerNew.vue'),
            name: 'admin.composer.new'

        }, {
            path: 'composers/:composerId',
            component: () => import('../components/admin/Composer.vue'),
            name: 'admin.composer'

        }, {
            path: 'instruments/new',
            component: () => import('../components/admin/InstrumentNew.vue'),
            name: 'admin.instrument.new'

        }, {
            path: 'instruments/:instrumentId',
            component: () => import('../components/admin/InstrumentUpdate.vue'),
            name: 'admin.instrument'

        }, {
            path: 'pieces/new/:composerId',
            component: () => import('../components/admin/PieceNew.vue'),
            name: 'admin.piece.new'

        }, {
            path: 'pieces/:pieceId',
            component: () => import('../components/admin/Piece.vue'),
            name: 'admin.piece'

        }
        ]
    },
    ],
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    if (store.state.user === false)
        store.dispatch('init').then(() => {
            next()
        })
    else
        next()
})

export default router
