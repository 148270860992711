var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", temporary: "", color: "#F8F1E7" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _vm.user
            ? _c(
                "div",
                { staticClass: "d-flex flex-column align-center mt-5" },
                [
                  _c("span", { staticClass: "visible-sm-and-down" }, [
                    _vm._v("Bienvenue " + _vm._s(_vm.user.name)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "visible-sm-and-down",
                      attrs: { text: "" },
                      on: { click: _vm.logout },
                    },
                    [_vm._v("Logout")]
                  ),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "d-flex justify-center visible-sm-and-down mt-5",
                },
                [_c("moclock-login")],
                1
              ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-5 text-h5 text-center text-uppercase" },
            [_vm._v("Menu")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            { staticClass: "mt-5", attrs: { to: "/", tag: "v-btn" } },
            [_vm._v("Accueil")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            { staticClass: "mt-2", attrs: { to: "/composers", tag: "v-btn" } },
            [_vm._v("Compositeurs")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            { staticClass: "mt-2", attrs: { to: "/pieces", tag: "v-btn" } },
            [_vm._v("Pièces")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            { staticClass: "mt-2", attrs: { to: "/contact", tag: "v-btn" } },
            [_vm._v("Contact")]
          ),
          _vm._v(" "),
          _vm.isAdmin
            ? [
                _c("v-divider", { staticClass: "my-10" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-h5 text-center text-uppercase" },
                  [_vm._v("Admin")]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-5",
                    attrs: { to: "/admin/composers", tag: "v-btn" },
                  },
                  [_vm._v("Compositeurs")]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-2",
                    attrs: { to: "/admin/pieces", tag: "v-btn" },
                  },
                  [_vm._v("Pieces")]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-2",
                    attrs: { to: "/admin/instruments", tag: "v-btn" },
                  },
                  [_vm._v("Instruments")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-app-bar",
        { attrs: { app: "", dense: "", color: "#F8F1E7" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("router-link", {
            attrs: { to: "/", custom: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ navigate, href }) {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "menu-link",
                        attrs: { href: href },
                        on: { click: navigate },
                      },
                      [
                        _c("svg-logo"),
                        _vm._v(" "),
                        _c("span", [_vm._v("Catalogue autour du violoncelle")]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm.user
            ? _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("span", { staticClass: "hidden-sm-and-down" }, [
                    _vm._v("Bienvenue " + _vm._s(_vm.user.name)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "hidden-sm-and-down",
                      attrs: { text: "" },
                      on: { click: _vm.logout },
                    },
                    [_vm._v("Logout")]
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "hidden-sm-and-down" },
                [_c("moclock-login")],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-main", [_vm._t("content")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }