<template>
  <v-app>
    <v-navigation-drawer app temporary v-model="drawer" color="#F8F1E7">
       <div v-if="user" class="d-flex flex-column align-center mt-5">
        <span class="visible-sm-and-down">Bienvenue {{ user.name }}</span>
        <v-btn text class="visible-sm-and-down" @click="logout">Logout</v-btn>
      </div>
      <div v-else class="d-flex justify-center visible-sm-and-down mt-5"><moclock-login></moclock-login></div>
      <div class="mt-5 text-h5 text-center text-uppercase">Menu</div>
      <v-btn to="/" tag="v-btn" class="mt-5">Accueil</v-btn>
      <v-btn to="/composers" tag="v-btn" class="mt-2">Compositeurs</v-btn>
      <v-btn to="/pieces" tag="v-btn" class="mt-2">Pièces</v-btn>
      <v-btn to="/contact" tag="v-btn" class="mt-2">Contact</v-btn>
      <template v-if="isAdmin">
        <v-divider class="my-10"></v-divider>
        <div class="text-h5 text-center text-uppercase">Admin</div>
        <v-btn to="/admin/composers" tag="v-btn" class="mt-5">Compositeurs</v-btn>
        <v-btn to="/admin/pieces" tag="v-btn" class="mt-2">Pieces</v-btn>
        <v-btn to="/admin/instruments" tag="v-btn" class="mt-2">Instruments</v-btn>
      </template>
    </v-navigation-drawer>
    <v-app-bar app dense color="#F8F1E7">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <router-link to="/" custom v-slot="{ navigate, href }">
        <a @click="navigate" :href="href" class="menu-link"><svg-logo></svg-logo> <span>Catalogue autour du violoncelle</span></a>
      </router-link>
      <v-spacer></v-spacer>
      <div v-if="user" class="d-flex align-center">
        <span class="hidden-sm-and-down">Bienvenue {{ user.name }}</span>
        <v-btn text class="hidden-sm-and-down" @click="logout">Logout</v-btn>
      </div>
      <div v-else class="hidden-sm-and-down"><moclock-login></moclock-login></div>
    </v-app-bar>
    <v-main>
      <slot name="content"></slot>
    </v-main>
    <!--  <v-footer app></v-footer> -->
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import svgLogo from "../../images/logo.svg";
import moclockLogin from "./auth/Login.vue";

export default {
  name: "App",
  components: {
    svgLogo,
    moclockLogin,
  },
  data() {
    return {
      drawer: false,
    };
  },
  watch: {
    //  drawer() {},
    filters: {
      user() {},
      deep: true,
    },
  },
  methods: {
    logout() {
      axios.post(`/logout`, this.form).then((res) => {
        location.reload();
      });
    },
  },
  computed: {
    isAdmin() {
      return this.user;
    },
    ...mapState({
      user: (state) => state.user,
    }),
  },
};
</script>

<style scoped lang="scss">
.menu-link {
  height: 100%;
  display: flex;
  align-items: center;
  color: black;
  text-decoration: none;

  svg {
    height: 60%;
  }
  span {
    line-height: 1em;
  }
}
</style>
