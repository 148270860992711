import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                'mo-color1': {
                    base: "#A56762"
                },
                'mo-color2': {
                    base: "#F8F1E7"
                }
            }
        },
    },
}

export default new Vuetify(opts)