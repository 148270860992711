/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
/* 
if (__webpack_public_path__ !== 'http://moclok.test:8081/')
    __webpack_public_path__ = window.location.origin + '/assets/' */

/* if (window.location.hostname === "moclock.musicaltools.eu")
    window.location.replace("https://moclok.musicaltools.eu") */

import './bootstrap';
import '../sass/app.scss';
import Vue from "vue";

import vuetify from './plugins/vuetify'

import store from './store/store'

import router from './router/router'

import App from './components/App.vue'

import VueFlags from "@growthbunker/vueflags";


Vue.use(VueFlags, {
    iconPath: '/flags/',
});

new Vue({
    el: '#app',
    components: {
        App
    },
    data: {
        loaded: false
    },
    vuetify,
    store,
    router
});
